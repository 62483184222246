import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { Campaign } from '@shared/accounts/models/account-campaign.model';
import { Account } from '@shared/accounts/models/account.model';
import { accountsActions } from '@shared/accounts/store/accounts.actions';
import { EditAccountType } from '@shared/accounts/types/edit-type.type';

export interface AccountsState {
  initialized: boolean;
  accounts: Account[];
  accountsTree: Account[];
  failedAccounts: Account[];
  campaigns: Campaign[];
  campaignsAccountIds?: string[];
  editAccountsStatus: { setting: EditAccountType; status: HttpErrorResponse; accounts: Account[] };
}

const initialState: AccountsState = {
  initialized: undefined,
  accounts: undefined,
  accountsTree: undefined,
  failedAccounts: undefined,
  campaigns: undefined,
  campaignsAccountIds: undefined,
  editAccountsStatus: undefined
};

export const accountsReducer = createReducer(
  initialState,

  on(accountsActions.loadAccountsFlat, (state): AccountsState => ({ ...state, initialized: true, accounts: undefined })),
  on(accountsActions.loadAccountsFlatReset, (state): AccountsState => ({ ...state, accounts: undefined })),
  on(accountsActions.loadAccountsFlatFinished, (state, { accounts }): AccountsState => ({ ...state, accounts })),
  on(accountsActions.loadAccountsTree, (state): AccountsState => ({ ...state, initialized: true, accountsTree: undefined })),
  on(accountsActions.loadAccountsTreeFinished, (state, { accountsTree }): AccountsState => ({ ...state, accountsTree })),
  on(accountsActions.loadAccountsTreeReset, (state): AccountsState => ({ ...state, accountsTree: undefined })),
  on(accountsActions.loadCampaigns, (state): AccountsState => ({ ...state, campaigns: undefined, campaignsAccountIds: undefined })),
  on(
    accountsActions.loadCampaignsFinished,
    (state, { campaigns, accountIds }): AccountsState => ({ ...state, campaigns, campaignsAccountIds: accountIds })
  ),
  on(accountsActions.loadCampaignsReset, (state): AccountsState => ({ ...state, campaigns: undefined, campaignsAccountIds: undefined })),
  on(accountsActions.editAccountFinished, (state, { editAccountsStatus }): AccountsState => ({ ...state, editAccountsStatus })),
  on(accountsActions.editAccountReset, (state): AccountsState => ({ ...state, editAccountsStatus: undefined })),
  on(accountsActions.initAccountsAndCampaigns, (state, { accounts, campaigns }): AccountsState => ({ ...state, accounts, campaigns }))
);
