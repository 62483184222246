import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { PipesCoreModule } from '@core/pipes/pipes-core.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AmazonWarningBarComponent } from '@shared/accounts/components/amazon-warning-bar/amazon-warning-bar.component';
import { TradedeskAuthPopupComponent } from '@shared/accounts/components/tradedesk-auth-popup/tradedesk-auth-popup.component';
import { UnauthenticateAccountsPopupComponent } from '@shared/accounts/components/unauthenticate-accounts-popup/unauthenticate-accounts-popup.component';
import { AccountsPlatformService } from '@shared/accounts/services/accounts-platform.service';
import { AccountsRestService } from '@shared/accounts/services/accounts-rest.service';
import { AccountsEffects } from '@shared/accounts/store/accounts.effects';
import { AccountsFacade } from '@shared/accounts/store/accounts.facade';
import { accountsReducer } from '@shared/accounts/store/accounts.reducer';
import { BusinessStructureSharedModule } from '@shared/business-structure/business-structure-shared.module';
import { NgxTableModule } from '@shared/ngx-table/ngx-table.module';
import { PopupsSharedModule } from '@shared/popups/popups-shared.module';

@NgModule({
  imports: [
    CommonModule,
    BusinessStructureSharedModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    EffectsModule.forFeature([AccountsEffects]),
    StoreModule.forFeature('accounts', accountsReducer),
    PopupsSharedModule,
    NgxTableModule,
    PipesCoreModule
  ],
  declarations: [TradedeskAuthPopupComponent, AmazonWarningBarComponent, UnauthenticateAccountsPopupComponent],
  exports: [AmazonWarningBarComponent, UnauthenticateAccountsPopupComponent]
})
export class AccountsSharedModule {
  static forRoot(): ModuleWithProviders<AccountsSharedModule> {
    return {
      ngModule: AccountsSharedModule,
      providers: [AccountsRestService, AccountsFacade, AccountsPlatformService]
    };
  }
}
