<popup
  titleAlign="left"
  contentAlign="left"
  class="unauthenticate-accounts-popup"
  [title]="popupTitle"
  [content]="popupContent"
  [buttons]="popupButtons"
  #popup
>
</popup>
<ng-template #popupTitle>
  <div class="d-flex font-size-20 font-weight-700">
    Unauthenticate <img class="ms-12" width="24" height="24" src="assets/platforms/{{ _selectedPlatformName }}.svg" />
  </div>
</ng-template>
<ng-template #popupContent>
  <ngx-table
    [dataSource]="_tableSource"
    [hasPagination]="false"
    (init)="_refreshPopupPosition()"
    (loading)="_refreshPopupPosition()"
    class="mdc-data-table__table--borderless"
  >
    <ng-template ngxColumn="name" header="Accounts that will be disconnected after unauthentication" let-account let-rowIndex="rowIndex">
      <div class="d-flex">
        <div class="row-index me-8">
          {{ rowIndex + 1 }}
        </div>
        <div class="d-flex flex-column justify-content-center">
          <span *ngIf="account?.mcc" class="f-size-10 color-neutral-variant--600">{{ account.mcc | uppercase }}</span>
          <span>{{ account.name }}</span>
        </div>
      </div>
    </ng-template>
  </ngx-table>
  <div class="warning-info">
    <mat-icon class="me-4 font-size-16 color-error--400">warning_amber</mat-icon>
    <p>
      By unauthenticating you revoke Octra’s access to your data. Your data will no longer be processed by Octra and therefore you will no
      longer be able to see above accounts in Octra. <br /><br />
      You can always authenticate again later if needed.
    </p>
  </div>
</ng-template>
<ng-template #popupButtons>
  <button type="button" mat-stroked-button color="secondary" (click)="popup.hide()">Cancel</button>
  <button type="button" mat-stroked-button color="warn" class="ms-auto" (click)="confirm()">Confirm and unauthenticate</button>
</ng-template>
