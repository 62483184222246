import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { PaginationQueryResult } from '@core/models/search-query.model';
import { ConnectedAccount } from '@shared/accounts/models/connected-account.model';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { NgxTableDatasource } from '@shared/ngx-table/interfaces/ngx-table-datasource.interface';
import { PopupComponent } from '@shared/popups/components/popup.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'unauthenticate-accounts-popup',
  templateUrl: './unauthenticate-accounts-popup.component.html',
  styleUrls: ['./unauthenticate-accounts-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnauthenticateAccountsPopupComponent {
  @ViewChild('popup') protected _popup: PopupComponent;
  @Output() confirmAction: EventEmitter<void> = new EventEmitter();

  protected _tableSource = new Subject<NgxTableDatasource<ConnectedAccount>>();
  protected _selectedPlatformName: string;
  protected _clientName: string;

  constructor(private readonly __cdr: ChangeDetectorRef, private readonly __clientFacade: ClientFacade) {}

  open(accounts: ConnectedAccount[], platformName: string): void {
    this._selectedPlatformName = platformName;
    this._popup.show();

    const pResult = new PaginationQueryResult<ConnectedAccount>();
    pResult.count = 0;
    pResult.current = 0;
    pResult.result = accounts;

    this._clientName = this.__clientFacade.selectedClient.name;

    this._tableSource.next(pResult);
    this.__cdr.detectChanges();
    this._popup.calculate();
  }

  confirm(): void {
    this.confirmAction.emit();
    this._popup.hide();
  }

  protected _refreshPopupPosition(): void {
    setTimeout(() => {
      this._popup.calculate();
      this.__cdr.detectChanges();
    }, 0);
  }
}
