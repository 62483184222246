import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { AccountsRestService } from '@shared/accounts/services/accounts-rest.service';
import { accountsActions } from '@shared/accounts/store/accounts.actions';
import { businessStructureSelectors } from '@shared/business-structure/store/business-structure.selectors';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { clientSelectors } from '@shared/clients/store/client.selectors';
import { PlatformsEnum } from '@shared/platforms/enums/platforms.enum';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, of, switchMap } from 'rxjs';

@Injectable()
export class AccountsEffects {
  loadAccountsTree$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(accountsActions.loadAccountsTree),
      concatLatestFrom(() => [
        this.__store.select(clientSelectors.selectClient),
        this.__store.select(businessStructureSelectors.selectBusinessStructureGeneral)
      ]),
      switchMap(([action, clientState, bsState]) =>
        this._accountsService$.getAccountsTree(clientState.client?.slug, action.platformSlug).pipe(
          map((accounts) => {
            accounts.sort((a, b) => {
              if (Number(b.level) !== Number(a.level)) {
                return Number(b.level) - Number(a.level);
              } else {
                return Number(b.parent_id) - Number(a.parent_id);
              }
            });
            const flattenDivisions = bsState.data ? bsState.data[clientState.client?.slug]?.flattenedDivisions || [] : [];
            accounts.map((account) => {
              account.current_client_id = clientState.client?.id;
              account.platform = action.platformSlug;
              account.is_mapped_to_current_client = account.getIsMappedToCurrentClient();
              account.is_mapped_to_other_client = account.getIsMappedToOtherClient();
              account.is_mapped_to_unaccessible_bu = account.getIsMappedToUnaccessibleBU(this.__clientFacade, flattenDivisions);
              account.selected = false;
            });
            return accounts;
          }),
          switchMap((accountsTree) => of(accountsActions.loadAccountsTreeFinished({ accountsTree })))
        )
      )
    )
  );

  loadAccountsFlat$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(accountsActions.loadAccountsFlat),
      concatLatestFrom(() => [this.__store.select(clientSelectors.selectClient), this.__store.select(clientSelectors.selectBusinessUnit)]),
      switchMap(([action, clientState, businessUnitState]) =>
        this._accountsService$
          .getAccountsFlatWithoutPagination(clientState.client?.slug, {
            platform: action.platformSlug,
            ...(businessUnitState.unit?.id ? { business_unit_id: businessUnitState.unit.id } : {})
          })
          .pipe(
            map((accounts) => accounts.sort((a, b) => (a.name > b.name ? 1 : -1))),
            switchMap((accounts) => of(accountsActions.loadAccountsFlatFinished({ accounts }))),
            catchError((error) => {
              this.__toastr.error('Something went wrong, could not fetch accounts');
              return of(accountsActions.loadAccountsFlatFinished({ accounts: [] }));
            })
          )
      )
    )
  );

  loadCampaigns$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(accountsActions.loadCampaigns),
      concatLatestFrom(() => [this.__store.select(clientSelectors.selectClient)]),
      switchMap(([action, clientState]) =>
        this._accountsService$.getAccountCampaigns(clientState.client?.slug, action.platformSlug, action.accountIds).pipe(
          switchMap((campaigns) => of(accountsActions.loadCampaignsFinished({ campaigns, accountIds: action.accountIds }))),
          catchError((error) => {
            this.__toastr.error('Something went wrong, could not fetch campaigns');
            return of(accountsActions.loadCampaignsFinished({ campaigns: [] }));
          })
        )
      )
    )
  );

  loadCampaignsFinished$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(accountsActions.loadCampaignsFinished),
      switchMap(() => of(accountsActions.loadCampaignsReset()))
    )
  );

  syncAccount$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(accountsActions.syncAccount),
      concatLatestFrom(() => [this.__store.select(clientSelectors.selectClient)]),
      switchMap(([action, clientState]) =>
        this._accountsService$
          .syncAccount(clientState.client?.slug, action.accounts)
          .pipe(
            switchMap(() =>
              of(accountsActions.editAccountFinished({ editAccountsStatus: { setting: 'sync', status: null, accounts: action.accounts } }))
            )
          )
      )
    )
  );

  mappingConnect$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(accountsActions.mappingConnect),
      switchMap((action) =>
        this._accountsService$.mappingConnect(action.clientOrSlug, action.platform, action.businessUnitId, action.accounts).pipe(
          switchMap(() => {
            const strongAuthEligibleAccounts = action.accounts.filter((account) => account.role === 'ADMIN');
            if (strongAuthEligibleAccounts.length && action.platform === PlatformsEnum.GOOGLE_ADS) {
              this.__store.dispatch(
                accountsActions.gaSendStrongAuthInvite({ accountIds: strongAuthEligibleAccounts.map((account) => account.account_id) })
              );
            }
            return of(
              accountsActions.editAccountFinished({ editAccountsStatus: { setting: 'connect', status: null, accounts: action.accounts } })
            );
          }),
          catchError((error) =>
            of(
              accountsActions.editAccountFinished({ editAccountsStatus: { setting: 'connect', status: error, accounts: action.accounts } })
            )
          )
        )
      )
    )
  );

  mappingToUnit$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(accountsActions.mappingUpdate),
      switchMap((action) =>
        this._accountsService$.mappingUpdate(action.clientOrSlug, action.platform, action.businessUnitId, action.accounts).pipe(
          switchMap(() =>
            of(accountsActions.editAccountFinished({ editAccountsStatus: { setting: 'remap', status: null, accounts: action.accounts } }))
          ),
          catchError((error) =>
            of(accountsActions.editAccountFinished({ editAccountsStatus: { setting: 'remap', status: error, accounts: action.accounts } }))
          )
        )
      )
    )
  );

  mappingDelete$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(accountsActions.mappingDelete),
      switchMap((action) =>
        this._accountsService$.mappingDelete(action.clientOrSlug, action.accounts).pipe(
          switchMap(() =>
            of(accountsActions.editAccountFinished({ editAccountsStatus: { setting: 'unmap', status: null, accounts: action.accounts } }))
          ),
          catchError((error) =>
            of(accountsActions.editAccountFinished({ editAccountsStatus: { setting: 'unmap', status: error, accounts: action.accounts } }))
          )
        )
      )
    )
  );

  editAccountFinished$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(accountsActions.editAccountFinished),
      switchMap(() => of(accountsActions.editAccountReset()))
    )
  );

  gaSendStrongAuthInvite$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(accountsActions.gaSendStrongAuthInvite),
      concatLatestFrom(() => [this.__store.select(clientSelectors.selectClient)]),
      switchMap(([action, clientState]) =>
        this._accountsService$.gaSendStrongAuthInvite(clientState.client?.slug, action.accountIds).pipe(
          switchMap(() => {
            this.__toastr.success('Admin access request was successfully sent');
            return of(
              accountsActions.gaSendStrongAuthInviteFinished(),
              accountsActions.loadAccountsTree({ platformSlug: PlatformsEnum.GOOGLE_ADS })
            );
          }),
          catchError((error) => {
            this.__toastr.error('Something went wrong, strong authorization invitation could not be sent');
            return of(
              accountsActions.loadAccountsTree({ platformSlug: PlatformsEnum.GOOGLE_ADS }),
              accountsActions.gaSendStrongAuthInviteFinished()
            );
          })
        )
      )
    )
  );

  metaSendStrongAuthInvite$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(accountsActions.metaSendStrongAuthInvite),
      concatLatestFrom(() => [this.__store.select(clientSelectors.selectClient)]),
      switchMap(([action, clientState]) =>
        this._accountsService$
          .metaSendStrongAuthInvite(clientState.client?.slug, action.accountId, action.accountParentId, action.access)
          .pipe(
            switchMap(() => {
              this.__toastr.success('Admin access request was successfully sent');
              return of(
                accountsActions.metaSendStrongAuthInviteFinished(),
                accountsActions.loadAccountsTree({ platformSlug: PlatformsEnum.META })
              );
            }),
            catchError((error) => {
              const errMessage =
                error.error && error.error.message ? error.error.message : 'Something went wrong, invitation could not be sent';
              this.__toastr.error(errMessage);
              return of(
                accountsActions.loadAccountsTree({ platformSlug: PlatformsEnum.META }),
                accountsActions.metaSendStrongAuthInviteFinished()
              );
            })
          )
      )
    )
  );

  loadAccountsFlatReset$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(accountsActions.loadAccountsFlatFinished),
      switchMap(() => of(accountsActions.loadAccountsFlatReset()))
    )
  );

  loadAccountsTreeReset$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(accountsActions.loadAccountsTreeFinished),
      switchMap(() => of(accountsActions.loadAccountsTreeReset()))
    )
  );

  constructor(
    private _accountsService$: AccountsRestService,
    private __actions$: Actions,
    private __store: Store,
    private __toastr: ToastrService,
    private __clientFacade: ClientFacade
  ) {}
}
